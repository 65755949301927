<!--eslint-disable-->
<template>
  <div v-if="$can('Read', 'Owner')">
    <b-row class="match-height">
      <b-col md="6" sm="12">
        <top-activities :uid="this.uid" />
      </b-col>
      <b-col md="6" sm="12">
        <activity-count :uid="this.uid" />
      </b-col>
      <b-col md="6" sm="12" v-if="sevenPlusAllowed">
        <seven-plus :uid="this.uid" />
      </b-col>
      <b-col md="6" sm="12" v-if="nineNowAllowed">
        <nine-now :uid="this.uid" />
      </b-col>
    </b-row>
  </div>
</template>
<!--eslint-enable-->

<script>
  import TopActivities from '@/components/charts/TopActivities.vue';
  import ActivityCount from '@/components/charts/ActivityCount.vue';
  import SevenPlus from '@/components/tables/SevenPlus.vue';
  import NineNow from '@/components/tables/NineNow.vue';
  import ApplicationsService from "@/services/ApplicationsService";

  export default {
    name: 'ActivityOverview',
    components: {
      NineNow,
      SevenPlus,
      TopActivities,
      ActivityCount,
    },
    props: {
      uid: {
        required: true,
        type: String,
      },
    },
    data() {
      return {
        sevenPlusAllowed: false,
        nineNowAllowed: false,
      };
    },
    mounted() {
      // Get the applications
      this.getApplications()
    },
    methods: {
      async getApplications() {
        const appRequest = await ApplicationsService.getApplications();
        if (appRequest.data) {
          appRequest.data.forEach(item => {
            if (item.package_name === 'com.madepurple.channel7') {
              this.sevenPlusAllowed = true
            }
            if (item.package_name === 'com.madepurple.channel9') {
              this.nineNowAllowed = true
            }
          })
        }
      },
    },
  };
</script>
